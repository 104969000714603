const $ = require('jquery');

import picturefill from 'picturefill';
import LazyLoad from 'vanilla-lazyload';
//import AOS from 'aos';
//import 'aos/dist/aos.css';
import slick from 'slick-carousel';

const script = (() => {

	// 変数宣言
	const pointSP = window.matchMedia('screen and (max-width: 767px)');

	// このJSのinit
	function init() {
/*
		AOS.init({
			offset: 100,
			duration: 1200,
			once: true
		});
*/
		lazyLoad();
		smoothScroll();
		menuTrigger();
		reserveTrigger();
		navAccordion();
		telLink();

		visualSlide();

		modal();

		// googlemap();
		slide();
		accordion();
	}

	// 関数 ------------------------------------
	function fixedBG() {
		scrollY = $(window).scrollTop();
		$('body').addClass('is-fixed').css('top', -scrollY);
	}

	function fixedBGReset() {
		$('body').removeClass('is-fixed').removeAttr('style');
		$('html, body').scrollTop(scrollY);
	}

	function lazyLoad() {
		const myLazyLoad = new LazyLoad({
			elements_selector: '.lazy'
		});
	}

	function smoothScroll() {
		$('a[href^="#"]').on('click.smoothScroll', (event) => {
			const speed = 1200;// ミリ秒
			const href = $(event.currentTarget).attr("href");
			const target = $(href == "#" || href == "" ? 'html' : href);
			const position = target.offset().top;
			$('html, body').animate({ scrollTop: position }, speed, 'swing');
			return false;
		});
	}

	function menuTrigger() {
		const $trigger = $('[aria-controls="nav-global"]');
		const $nav = $('.' + $trigger.attr('aria-controls'));

		$trigger.on('click', () => {
			const expanded = $trigger.attr('aria-expanded');

			if (expanded === 'false') {
				$trigger.attr('aria-expanded', true);
				$nav.removeClass('is-passive').attr('aria-hidden', false);
				fixedBG();
			} else {
				$trigger.attr('aria-expanded', false);
				$nav.addClass('is-passive').attr('aria-hidden', true);
				fixedBGReset();
			}
		});

		function checkBreakPoint(pointSP) {
			if (!pointSP.matches) {
				$trigger.attr('aria-expanded', false);
				$nav.attr('aria-hidden', true);
				fixedBGReset();
			}
		}

		pointSP.addListener(checkBreakPoint);
	}

	function reserveTrigger() {
		const $trigger = $('[aria-controls="nav-reserve"]');
		const $nav = $('.' + $trigger.attr('aria-controls'));

		$trigger.on('click', () => {
			const expanded = $trigger.attr('aria-expanded');

			if (expanded === 'false') {
				$trigger.attr('aria-expanded', true);
				$nav.attr('aria-hidden', false);
				fixedBG();
			} else {
				$trigger.attr('aria-expanded', false);
				$nav.attr('aria-hidden', true);
				fixedBGReset();
			}
		});
	}

	function navAccordion() {
		const $trigger = $('[aria-controls="nav-global-child"]');
		const $child = $trigger.next();

		function checkBreakPoint(pointSP) {
			if (pointSP.matches) {
				$trigger.attr('aria-expanded', false);
				$child.attr('aria-hidden', true);


				$trigger.on('click', () => {
					const expanded = $trigger.attr('aria-expanded');

					if (expanded === 'false') {
						$trigger.attr('aria-expanded', true);
						$child.stop().slideDown().attr('aria-hidden', false);
					} else {
						$trigger.attr('aria-expanded', false);
						$child.stop().slideUp().attr('aria-hidden', true);
					}

					return false;
				});
			} else {
				$trigger.attr('aria-expanded', true);
				$child.attr('aria-hidden', false);

				$trigger.off();
				$child.removeAttr('style');
			}
		}

		pointSP.addListener(checkBreakPoint);
		checkBreakPoint(pointSP);
	}

	function telLink() {
		const $tel = $('.js-tel');

		$tel.each((index, element) => {
			const $self = $(element);
			const str = $self.text();

			$self.attr('href', 'tel:' + str.replace(/-/g, ''));
		});
	}

	function visualSlide() {
		const $visual = $('.visual-index');
		const $slide = $visual.find('.visual-index__slide').not('.slick-initialized');

		$slide.slick({
			autoplay: true,
			autoplaySpeed: 4500,
			arrows: false,
			fade: true,
			pauseOnHover: false,
			speed: 3000
		});

		const $item = $visual.find('.slick-slide');
		$item.eq(0).addClass('-animation');

		$slide.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			$item.eq(nextSlide).addClass('-animation');

			setTimeout(function() {
				$item.eq(currentSlide).removeClass('-animation');
			}, 3000);
		});
	}

	function modal() {
		$(document).on('click', '[aria-controls="modal"]', (event) => {
			event.preventDefault();

			const expanded = $(event.currentTarget).attr('aria-expanded');
			const link = $(event.currentTarget).attr('href');

			if (expanded === 'false') {
				$('body').append('<div class="modal" aria-hidden="true"><div class="modal__overlay" aria-expanded="false" aria-controls="modal"></div><div class="modal__contents js-modal-target"></div></div>');

				const $modal = $('.' + $(event.currentTarget).attr('aria-controls'));
				const $el = $modal.find('.js-modal-target');

				$el.load(link + ' .js-modal-detail', () => {
					$('[aria-controls="modal"]').attr('aria-expanded', true);
					$('.' + $('[aria-controls="modal"]').attr('aria-controls')).attr('aria-hidden', false);
					setTimeout(function() {
						$('button[aria-controls="modal"]').attr('data-link', link).focus();
					}, 800);
				});
				fixedBG();
			} else {
				const $modal = $('.' + $(event.currentTarget).attr('aria-controls'));
				const link = $(event.currentTarget).data('link');

				$('[aria-controls="modal"]').attr('aria-expanded', false);
				$modal.addClass('is-passive').attr('aria-hidden', true);
				$('[href="' + link + '"]').focus()
				setTimeout(function() {
					$modal.remove();
				}, 1000);
				fixedBGReset();
			}
		});
	}

	function googlemap() {
		const $map = $('[id^="map"]');
		if($map.length) {
			const latlng = new google.maps.LatLng($map.data('lat'), $map.data('lng'));
			const myOptions = {
				zoom: 17,/*拡大比率*/
				center: latlng,/*表示枠内の中心点*/
				scrollwheel: false,/*スクロール不可*/
				mapTypeControlOptions: { mapTypeIds: ['map', google.maps.MapTypeId.ROADMAP] }/*表示タイプの指定*/
			};
			const icon = {
				url: '/assets/img/common/ic_pin.svg',
				scaledSize: new google.maps.Size(160, 119),
			}
			const map = new google.maps.Map(document.getElementById('map'), myOptions);
			const markerOptions = {
				position: latlng,
				map: map,
				icon: icon,
				title: 'アロマヒーリング中目黒本店'
			};
			const marker = new google.maps.Marker(markerOptions);
			/*取得スタイルの貼り付け*/

			var styleOptions = [
				{
					"stylers": [
							{
								"hue": "#999999"
							},
							{
								"saturation": -255
							}
					],
					"elementType": "all",
					"featureType": "all"
				}
			];
			const styledMapOptions = { name: 'アロマヒーリング中目黒本店' };
			const mapType = new google.maps.StyledMapType(styleOptions, styledMapOptions);
			map.mapTypes.set('map', mapType);
			map.setMapTypeId('map');
		}
	}

	function slide() {
		const $target = $('.js-slide');
		if($target.length) {
			const $slide = $target.not('.slick-initialized');
			const $thumb = $('.js-slide-thumb').not('.slick-initialized');

			$slide.slick({
				autoplay: true,
				autoplaySpeed: 3000,
				asNavFor: '.js-slide-thumb',
				speed: 500
			});
			$thumb.slick({
				asNavFor: '.js-slide',
				focusOnSelect: true,
				slidesToShow: 5,
				responsive: [{
					breakpoint: 767,
						settings: {
							slidesToShow: 3
					}
				}]
			});
		}
	}

	function accordion() {
	  const $trigger = $('[aria-controls*="accordion"]');
	  $trigger.stop().on('click', (event) => {
			const $self = $(event.currentTarget);
			const expanded = $self.attr('aria-expanded');
			const $target = $self.next();

			if (expanded === 'false') {
				$self.attr('aria-expanded', true).addClass('is-active');
				$target.attr('aria-hidden', false).slideDown();
			} else {
				$self.attr('aria-expanded', false).removeClass('is-active');
				$target.attr('aria-hidden', true).slideUp();
			}
	  });
	 }
	// -----------------------------------------

	return {
		init : init
	};

})();

// このタイミングで実行
$(() => {
	script.init();
});